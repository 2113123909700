
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "own-building-addition-step-3",
  components: { Field, ErrorMessage },
  props: {
    premiumCalculationRoute: String,
    packageId: String,
    policyId: String,
    thirdPartLiabilityValueRate: Number,
  },
  data() {
    return {
      thirdPartyLiabilityLimit: "",
      benefitLimit: "",
      medicalRate: "",
      includeMedicalExpenses: false,
      totalPremium: 0.0 as number,
    };
  },
  methods: {
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      let value: any = variables.removeComma(newValue);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        const payload = {
          businessClass: "HOM",
          package: this.packageId,
          value: value,
          rate: event.target.accessKey,
        } as object;

        payload[fieldName] = value;
        value = String(value);

        if (JwtService.getToken() && value?.length > 5) {
          ApiService.setHeader();
          ApiService.post(`${this.premiumCalculationRoute}`, payload)
            .then(({ data }) => {
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    // getRates() {
    //   //Fetch list of underwriters for house building insurance package
    //   if (JwtService.getToken()) {
    //     ApiService.setHeader();
    //     ApiService.get(variables.HOUSE_PERSONAL_ACCIDENT_RATE_ROUTE)
    //       .then(({ data }) => {
    //         //Assign data to form fields
    //         this.rate = data.data.accidentalDeath.value;
    //         this.medicalRate = data.data.reasonableMedicalExpenses.value;
    //       })
    //       .catch(function(error) {
    //         variables.toastAlert(error.response.data.error, "error");
    //       });
    //   }
    // },
    mdeicalPremium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setTimeout(() => {
        this.$nextTick(() => (event.target.value = newValue));
      }, 0.1);

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false && event.target.value.length > 3) {
        // Calculate premium from vehicle value
        const payload = {
          businessClass: "HOM",
          package: this.packageId,
          value: value,
          rate: event.target.accessKey,
        } as object;

        payload[fieldName] = value;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(`${this.premiumCalculationRoute}`, payload)
            .then(({ data }) => {
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }

      if (this.includeMedicalExpenses) {
        // Set check to false
        this.includeMedicalExpenses = !this.includeMedicalExpenses;
        this.medicalExpenses();
      }
    },
    medicalExpenses() {
      this.includeMedicalExpenses = !this.includeMedicalExpenses;

      const paValue = document.getElementById(
        "personal-accident-value"
      ) as HTMLElement;

      const medicalExpensesPremiumText = document.getElementById(
        "medical-expenses-premium"
      ) as HTMLElement;

      // const totalPremiumText = document.getElementById(
      //   "total-premium"
      // ) as HTMLElement;

      if (this.includeMedicalExpenses) {
        const premiumValue = variables.removeComma(paValue.textContent);
        // const value = this.medicalRate * premiumValue;
        const value = premiumValue;
        let newPremium = variables.totalPremiumValue() + value;
        newPremium = parseFloat(newPremium.toFixed(2));

        if (isNaN(newPremium) == false) {
          // medicalExpensesPremiumText.textContent = this.addCommaToNumberString(
          //   value
          // );
          // this.totalPremium = newPremium;
          // totalPremiumText.textContent = this.addCommaToNumberString(
          //   newPremium
          // );

          const payload = {
            businessClass: "HOM",
            package: this.packageId,
            benefitLimit: premiumValue,
            includeMedicalExpenses: true,
          } as object;

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${this.premiumCalculationRoute}`, payload)
              .then(({ data }) => {
                variables.premiumCalculator(
                  0,
                  data.data,
                  // data.data.grossPremium,
                  "medical-expenses-premium"
                );

                // Append total premium value tot total premium field
                this.totalPremium = variables.totalPremiumValue();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }
      } else {
        this.totalPremium = variables.totalPremiumValue();
        medicalExpensesPremiumText.textContent = "0";
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    updateMedicalExpenses(event) {
      this.includeMedicalExpenses = event.target.checked;
      this.medicalExpenses();
    },
  },
});
